exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-b-2-bpartner-js": () => import("./../../../src/pages/b2bpartner.js" /* webpackChunkName: "component---src-pages-b-2-bpartner-js" */),
  "component---src-pages-bedingungen-js": () => import("./../../../src/pages/bedingungen.js" /* webpackChunkName: "component---src-pages-bedingungen-js" */),
  "component---src-pages-danke-js": () => import("./../../../src/pages/danke.js" /* webpackChunkName: "component---src-pages-danke-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

